import React, { useState, useEffect }  from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import RegisterLookupForm from "./RegisterLookupForm";

import '../Common.css';
import './RegisterLookupPage.css';

// assets
import Header_Logo from '../../media/ablesun/register/lookup/head.svg';
// import Value_Date from '../media/5.date.svg';
// import Value_Location from '../media/6.location.svg';

// import VideoPoster from "../media/poster.jpg";
// import Video_mp4 from "../media/KakaoTalk_Video_2024-02-24-15-43-14.mp4";
// import Video_webm from "../media/s2_1080p_25f_cq48.webm";

// end of assets

export default function RegisterLookupPage() {
  const location = useLocation();
  const navigate = useNavigate();
  // const eventState = { ...location.state.event };
  // const itemState = { ...location.state.item };
  // const codeState = { ...location.state };

  const registationState = { ...location.state };
  console.log(registationState)
  
  useEffect(() => {
      // if(codeState.code === undefined || codeState.code === null){
      //   // navigate("/");
      // }
   });

  return (
    // css body background color override

    
    <div className="contents">
      {/* <style>{'body { background-color: #B4B4B4; }'}</style> */}

      {/* <video 
        className="bg-video-register"
        playsInline 
        loop 
        muted 
        // autoplay 
        autotart="true"
        autoPlay 
        disableRemotePlayback
        // type="video/mp4"
        poster={VideoPoster}
        > */}
          {/* <source type="video/webm" src={Video_webm} /> */}
          {/* <source type="video/mp4" src={Video_mp4} />
        </video> */}

      <div className="register-form">
        <div className="register-form-logo">
          <img src={Header_Logo} alt="header" />
        </div>

        {/* <hr className="no-color"/> */}
        {/* input fields */}
        <RegisterLookupForm />
        {/* <h1>{codeState.code}</h1> */}
      </div>
    </div>
  );
}
