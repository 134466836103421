import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FerrisWheelSpinnerOverlay } from 'react-spinner-overlay'
import SweetAlert2 from 'react-sweetalert2';
import axios from "axios";

import btn_dec from '../../media/ablesun/register/btn_dec.svg';
import btn_inc from '../../media/ablesun/register/btn_inc.svg';
import btn_register from '../../media/ablesun/register/btn_register.svg';


export default function RegisterForm() { 
  let [loading, setLoading] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [codeProps, setCodeProps] = useState();
  const [registerProps, setRegisterProps] = useState({});

  const location = useLocation();
  
  const eventState = location.state?.event ? { ...location.state.event } : null;
  const itemState = location.state?.item ? { ...location.state.item } : null;
  const codeState = location.state?.code ?  location.state.code : null;

  // console.log(eventState)
  // console.log(itemState)
  console.log(codeState)

  const navigate = useNavigate();

  const [ticketCount, setTicketCount] = useState(1);  
  const ticketPrice = itemState?.item_price;  
  const maxPurchaseLimit = itemState?.max_purchase_limit; // Get the max purchase limit from itemState

  useEffect(() => {
    // Redirect to main page if eventState or event_no is not available
    if (!eventState || eventState.event_no === undefined) {
      navigate("/");
    }
  }, [eventState, navigate]); // Add navigate and eventState as dependencies

  useEffect(() => {
    console.log(codeState)
    if (codeState) {
      const button = document.getElementById('btn-register');  // Select the button element
      button.classList.add('special-mode');  // Add 'special-mode' class when daCode is set
    }
      
  }, [codeState]); 


  // Function to increase ticket count with max purchase limit control
  const increaseTicketCount = () => {
    if (ticketCount < maxPurchaseLimit) {
      setTicketCount(prevCount => prevCount + 1);
    }
  };

  // Function to decrease ticket count, minimum is 1
  const decreaseTicketCount = () => {
    if (ticketCount > 1) {
      setTicketCount(prevCount => prevCount - 1);
    }
  };
  
  const formatPrice = (price) => {
    return price.toLocaleString('en-US'); // Formats the number with commas
  };

  const makeRegister = (event) => {
    event.preventDefault(); // Prevent page refresh

    if (!event.target.name.value || !event.target.email.value || !event.target.tel.value) {
      setSwalProps({
        show: true,
        icon: "warning",
        html: '내용을 입력해주세요.<br>plase fill all forms.',
        customClass: {
          popup: 'alert-popup',
          confirmButton: 'alert-popup-confirm-button',
        },
        backdrop: true,
        background: '#000',
        color: '#fff',
      }); 
      return;
    }

    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/event/${eventState.event_no}/register`, {
      // registration_item_code: event.target.code.value,
      input_bank_name: event.target.name.value,
      input_email: event.target.email.value,
      input_call: event.target.tel.value,
      ticket_count: ticketCount, // Send the ticket count
      item_code: codeState, // Send the item code
    // {
    //   "input_bank_name": "JohnBank",   // 은행명 (최대 12자)
    //   "input_call": "010-1234-5678",   // 전화번호 (최대 15자)
    //   "input_email": "john@example.com", // 이메일 (최대 255자)
    //   "ticket_count": 5          // 구매 티켓 수
    // }

    }).then((response) => {
      const result = response.data;
      console.log(result)
      if (response.status !== 200) {
        alert("서버 오류가 발생하였습니다.\nServer error has occurred.\nhello@suncollaboration.live로 문의해주세요(please contact us)");
      }
      if (result.reg_data.reg_uid) {
        setLoading(false);
        setSwalProps({
          show: true,
          icon: "success",
          html: '등록되었습니다<br>메일을 확인해주세요.<br>You have been registered<br>Please check your email.',
          customClass: {
            popup: 'alert-popup',
            confirmButton: 'alert-popup-confirm-button',
          },
          backdrop: true,
          background: '#000',
          color: '#fff',
        });
        setRegisterProps({
          registration: true,
          registration_uid: result.reg_data.reg_uid,
          registration_mail: result.reg_data.reg_mail,
        });
      } else {
        setLoading(false);
        setSwalProps({
          show: true,
          icon: "error",
          html: '등록에 실패하였습니다.<br>Failed to register. <br>hello@suncollaboration.live로 문의해주세요(please contact us)',
          customClass: {
            popup: 'alert-popup',
            confirmButton: 'alert-popup-confirm-button',
          },
          backdrop: true,
          background: '#000',
          color: '#fff',
        });
        
        return;
      }
    }).catch(error => {
      if (error.response) {
        console.log(error.response);
        const decodedMessages = []; // Array to store decoded messages

        // Loop through the errors object and decode each message
        for (const key in error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty(key)) {
                const encodedMessages = error.response.data.errors[key];
                encodedMessages.forEach(encodedMessage => {
                    const decodedMessage = decodeURIComponent(encodedMessage);
                    decodedMessages.push(decodedMessage); // Add each decoded message to the array
                });
            }
        }

        console.log(decodedMessages); 
        setLoading(false);
        setSwalProps({
          show: true,
          icon: "error",
          html: decodedMessages.join('<br>'), // Join the decoded messages with a line break
          customClass: {
            popup: 'alert-popup',
            confirmButton: 'alert-popup-confirm-button',
          },
          backdrop: true,
          background: '#000',
          color: '#fff',
        });
        return;

      }
    });;
  };

  return (
    <form onSubmit={makeRegister}>
        {/* date */}
        <div className="register-form-subject register-fields no-margin-bottom">
          <div className="register-form-title">
            <div className="title"> 
            티켓 정보
            </div>
            {/* <div className="label"> 
            Ticket Info
            </div> */}
          </div>
          <div className="register-form-value">
            <div >{eventState?.event_name}</div>
            <div className="label">{itemState?.item_name}</div>
          </div>
        </div>
      {/* name */}
      <div className="register-form-subject register-fields no-margin-bottom">
        <div className="register-form-title">
          <div className="title"> 입금자명 </div>
          {/* <div className="label"> Name </div> */}
        </div>
        <div className="register-form-value">
          <input type="text" name="name" maxLength={5}/>
        </div>
      </div>
      {/* Ticket Count */}
      <div className="register-form-subject register-fields no-margin-bottom">
        <div className="register-form-title">
          <div className="title"> 예매수량 </div>
          {/* <div className="label"> Ticket Count </div> */}
        </div>
        <div className="register-form-value">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{marginLeft: "1em"}}>
                <button 
                    type="button" 
                    className="btn-counter"
                  onClick={decreaseTicketCount} 
                  // style={counterButtonStyle} 
                disabled={ticketCount === 1}
                style={{marginRight: "1em"}}
                >
                  <img src={btn_dec} alt="-"></img>
                </button>
                <span style={{ margin: "0 10px", fontSize: "1.8em" }}>{ticketCount}</span>
                <button 
                    type="button" 
                    className="btn-counter"
                  onClick={increaseTicketCount} 
                  // style={counterButtonStyle} 
                  disabled={ticketCount === maxPurchaseLimit}
                  style={{marginLeft: "1em"}}
                >
                  <img src={btn_inc} alt="+"></img>
                  </button>
              </div>
            <span style={{  textAlign: "right" ,  fontSize: "1.8em" }}>₩ {formatPrice(ticketCount * ticketPrice)}</span>
          </div>
        </div>
      </div>
      {/* email */}
      <div className="register-form-subject register-fields no-margin-bottom">
        <div className="register-form-title">
          <div className="title"> 이메일 </div>
          {/* <div className="label"> E-mail </div> */}
        </div>
        <div className="register-form-value">
          <input type="email" name="email" />
        </div>
      </div>

      {/* phone Number */}
      <div className="register-form-subject register-fields">
        <div className="register-form-title">
          <div className="title"> 연락처 </div>
          {/* <div className="label"> Cell Phone </div> */}
        </div>
        <div className="register-form-value">
          <input type="tel" name="tel"/>
        </div>
      </div>

      <button 
        id="btn-register"
        type="submit" 
        className="svg-btn btn-register"
      >    
        <img src={btn_register} alt="동의 후 제출하기"></img>
      </button>


      <FerrisWheelSpinnerOverlay
        loading={loading} 
        overlayColor="rgba(0,0,0, 0.6)"
        color="#fff"
        size={30} />
      <SweetAlert2 {...swalProps}
        onResolve={result => {
          setSwalProps({ show: false });
          if (registerProps.registration) {
            navigate("/register/lookup", { state: registerProps });
          }
        }}
      />
    </form>
  );
}

