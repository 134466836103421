import './App.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";
// import Register from "./pages/Register";
import Main from "./pages/Main";
import RegisterPage from "./pages/register/RegisterPage";
import RegisterLookupPage from "./pages/register/RegisterLookupPage";
import RegisterInfoPage from "./pages/register/RegisterInfoPage";

function App() {
  return (
    <Routes>
      {/* Main */}
      <Route path="/" element={<Main />} />
      {/* <Register /> */}
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/register/lookup" element={<RegisterLookupPage />} />
      <Route path="/register/info" element={<RegisterInfoPage />} />
    </Routes>
  );
}

export default App;
