import React, { useState, useEffect }  from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import RegisterInfoForm from "./RegisterInfoForm";

import '../Common.css';
import './RegisterLookupPage.css';

// assets
import Header_Logo from '../../media/ablesun/register/info/head.svg';
// import Value_Date from '../media/5.date.svg';
// import Value_Location from '../media/6.location.svg';

// import VideoPoster from "../media/poster.jpg";
// import Video_mp4 from "../media/KakaoTalk_Video_2024-02-24-15-43-14.mp4";
// import Video_webm from "../media/s2_1080p_25f_cq48.webm";

// end of assets

export default function RegisterInfoPage() {
  const location = useLocation();
  const navigate = useNavigate();

  // const eventState = { ...location.state.event };
  // const itemState = { ...location.state.item };
  // const registerState = { ...location.state.registration };

  // console.log(eventState, itemState, registerState);

  return (
    // css body background color override

    
    <div className="contents">
      <div className="register-form">
        <div className="register-form-logo">
          <img src={Header_Logo} alt="header" />
        </div>

        {/* <hr className="no-color"/> */}
        {/* input fields */}
        <RegisterInfoForm />
        {/* <h1>{codeState.code}</h1> */}
      </div>
    </div>
  );
}
