import React, {useEffect, useState} from "react";
import './Common.css';
import './Main.css';

import RegisterInit from "./register/RegisterInit";
import axios from "axios";

// assets
// import Video_raw from "../media/2_raw.mp4";
// import Video_mp4 from "../media/2_1080p_25f_cq39.mp4";
import Video_mp4 from "../media/ablesun/poster.mp4";
import Video_webm from "../media/ablesun/poster.webm";
import VideoPoster from "../media/ablesun/poster.jpg";
import ImagePosterDesc from "../media/ablesun/main/main_text_1.svg";

// btn
import BtnRegister from "../media/ablesun/main/btn_register.svg";
import BtnRegisterCheck from "../media/ablesun/main/btn_register_check.svg";

export default function Main() {
    
  const [daCode, setDaCode] = useState(null);
  const [clickCount, setClickCount] = useState(0);  // Track click count
  const clickLimit = 5;  // Trigger after 5 clicks
  // const clickTimeout = 5000; // Timeout (in ms) to reset the counter if clicks are slow

  const specialMode = (event) => {
    const theCode = prompt('SOMETHING SPECIAL?');
    setDaCode(theCode);
  };

  // Handle the image click
  const handleImageClick = () => {
    setClickCount(prev => prev + 1); // Increment the click counter

    // If the click count reaches the limit, trigger the specialMode
    if (clickCount + 1 >= clickLimit) {
      specialMode();
      setClickCount(0); // Reset the click counter after triggering specialMode
    }

    // // Reset the counter if clicks are slow
    // setTimeout(() => {
    //   setClickCount(0);
    //   console.log('Click time reset');
    // }, clickTimeout);
  };



  return (
    <div className="contents">
      
      {/* <h1>Main</h1> */}
      <div className="video">
        <video 
        className="bg-video"
        playsInline 
        loop 
        muted 
        // autoplay 
        autotart="true"
        autoPlay 
        disableRemotePlayback
        // type="video/mp4"
          poster={VideoPoster}
        // height="680px"
        >
          <source type="video/mp4" src={Video_mp4} />
          <source type="video/webm" src={Video_webm} />
         
  
        </video>
        <div className="bottom-area">
          <img src={ImagePosterDesc} className="bg-video" alt="poster"  onClick={handleImageClick}/>

          {/* <hr className="no-color"/> */}
          <RegisterInit daCode={daCode} />
          {/* <input type="submit" value="확인 / Submit" /> */}
        </div>

      </div>


    
    </div>

  );
}
