import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FerrisWheelSpinnerOverlay } from 'react-spinner-overlay'
import SweetAlert2 from 'react-sweetalert2';
import axios from "axios";

import btn_lookup from '../../media/ablesun/register/lookup/btn_lookup.svg';

export default function RegisterLookupForm() { 
  let [loading, setLoading] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [codeProps, setCodeProps] = useState({});
  const [registerProps, setRegisterProps] = useState({});

  const location = useLocation();
  
  const navigate = useNavigate();

  const registationState = { ...location.state };

  
  useEffect(() => {
    console.log(registationState)
    // Check the registration state and simulate an event object
    if (
      registationState.registration === true || 
      registationState.registration_uid != null || 
      registationState.registration_mail != null
    ) {
      const simulatedEvent = {
        preventDefault: () => {}, // No page refresh
        target: {
          email: { value: registationState.registration_mail }, // Simulate the email field
          register_uid: { value: registationState.registration_uid }, // Simulate the register_uid field
        },
      };

      lookupRegister(simulatedEvent); // Call the lookupRegister function with the simulated event
    }
  });
  
  const lookupRegister = (event) => {
    event.preventDefault(); // Prevent page refresh

    if (!event.target.email.value || !event.target.register_uid.value) {
      setSwalProps({
        show: true,
        icon: "warning",
        html: '내용을 입력해주세요.<br>plase fill all forms.',
        customClass: {
          popup: 'alert-popup',
          confirmButton: 'alert-popup-confirm-button',
        },
        backdrop: true,
        background: '#000',
        color: '#fff',
      }); 
      return;
    }

    setLoading(true);
    axios.post(`${process.env.REACT_APP_API_URL}/register/info`, {
      input_reg_uid: event.target.register_uid.value,
      input_email: event.target.email.value,

    // {
    //   "input_reg_uid": "019618421515",   // 예매번호 (최대 16자)
    //   "input_email": "test@ttttt.com", // 이메일 (최대 255자)
    // }
    
    // {
    //   "input_reg_uid": "046860881386",   // 예매번호 (최대 16자)
    //   "input_email": "john@example.com", // 이메일 (최대 255자)
    // }
    

    }).then((response) => {
      const result = response.data;
      if (response.status !== 200) {
        alert("서버 오류가 발생하였습니다.\nServer error has occurred.\nhello@suncollaboration.live로 문의해주세요(please contact us)");
      }
      if (result.reg_data.reg_uid) {
        setLoading(false);
        setRegisterProps({
          registration_uid: result.reg_data.reg_uid,
          registration_mail: result.reg_data.reg_mail,
        });
        navigate("/register/info", {
          state:
            { event: result.event_data, item: result.item_data, registration: result.reg_data }
        });

      }
    }).catch(error => {
      if (error.response) {
        console.log(error.response);
        setLoading(false);
        if (error.response.status === 404) {
          // server error
          
          setSwalProps({
            show: true,
            icon: "error",
            html: '내역이 없습니다.<br>Cannot Find Register. <br>hello@suncollaboration.live로 문의해주세요<br>(please contact us)',
            customClass: {
              popup: 'alert-popup',
              confirmButton: 'alert-popup-confirm-button',
            },
            backdrop: true,
            background: '#000',
            color: '#fff',
          });
          
          return;
        } else {
          const decodedMessages = []; // Array to store decoded messages
          // Loop through the errors object and decode each message
          for (const key in error.response.data.errors) {
              if (error.response.data.errors.hasOwnProperty(key)) {
                  const encodedMessages = error.response.data.errors[key];
                  encodedMessages.forEach(encodedMessage => {
                      const decodedMessage = decodeURIComponent(encodedMessage);
                      decodedMessages.push(decodedMessage); // Add each decoded message to the array
                  });
              }
          }
          console.log(decodedMessages); 
          setSwalProps({
            show: true,
            icon: "error",
            html: decodedMessages.join('<br>'), // Join the decoded messages with a line break
            customClass: {
              popup: 'alert-popup',
              confirmButton: 'alert-popup-confirm-button',
            },
            backdrop: true,
            background: '#000',
            color: '#fff',
          });
          return;
        }
      }
    });;
  };

  return (
    <form onSubmit={lookupRegister}>
      {/* register_uid */}
      <div className="register-form-subject register-fields no-margin-bottom">
        <div className="register-form-title">
          <div className="title"> 예매번호 </div>
          {/* <div className="label"> Registration Number </div> */}
        </div>
        <div className="register-form-value" >
          <input type="text" name="register_uid" maxLength={16}/>
        </div>
      </div>
      {/* email */}
      <div className="register-form-subject register-fields no-margin-bottom">
        <div className="register-form-title">
          <div className="title"> 이메일 </div>
          {/* <div className="label"> E-mail </div> */}
        </div>
        <div className="register-form-value" >
          <input type="email" name="email" />
        </div>
        
      </div>
      <div className="label name-info" style={{marginLeft: 0}}>
      * 예매 번호를 잊어버리셨거나 예매 후 이메일을 수신받지 못하셨다면<br />
      hello@suncollaboration.live로 연락주시기 바랍니다.
      </div>
      {/* <input type="hidden" name='code' value={codeState.code} /> */}

      {/* <input type="submit" className="agree-submit font500" value="전체 동의 및 제출&#10;Agree and Submit" /> */}
      <button 
        id="btn-lookup"
        type="submit" 
        className="btn-lookup"
        // submit
        // onClick={lookupRegister}
      >    
        <img src={btn_lookup} alt="예매 내역 조회"></img>
      </button>

      <FerrisWheelSpinnerOverlay
        loading={loading} 
        overlayColor="rgba(0,0,0, 0.6)"
        color="#fff"
        size={30} />
      <SweetAlert2 {...swalProps}
        onResolve={result => {
          setSwalProps({ show: false });
          // if (registerProps.registration) {
          //   navigate("/register/lookup", { state: registerProps });
          // }
        }}
      />
    </form>
  );
}

