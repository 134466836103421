import React, { useState, useEffect }  from "react";
import { Link, useNavigate } from "react-router-dom";
import { FerrisWheelSpinnerOverlay } from 'react-spinner-overlay'
import './RegisterInit.css';
import SweetAlert2 from 'react-sweetalert2';
import axios from "axios";

import btn_register from '../../media/ablesun/main/btn_register.svg';
import btn_register_check from '../../media/ablesun/main/btn_register_check.svg';

export default function RegisterInit({ daCode }) { 
  let [loading, setLoading] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [codeProps, setCodeProps] = useState();
  const navigate = useNavigate();

  // This function now returns a Promise
  const checkCodeLegit = async (code) => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/event/1/item/${code}`, { validateStatus: false });
      setLoading(false);

      const result = response.data;
      if (result?.item?.item_name) {
        console.log("found special ticket");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error while checking code legitimacy:", error);
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    const button = document.getElementById('btn-register');  // Select the button element

    const checkAndUpdate = async () => {
      if (daCode) {
        console.log("daCode changed:", daCode);
        const isLegit = await checkCodeLegit(daCode);  // Wait for the result of checkCodeLegit
        console.log(isLegit);

        if (isLegit) { 
          button.classList.add('special-mode');  // Add 'special-mode' class when daCode is set
          setCodeProps(daCode);  // Set the codeProps state with the daCode
        }
      } else {
        button.classList.remove('special-mode');  // Remove 'special-mode' class if daCode is not set
      }
    };

    checkAndUpdate();  // Call the async function

  }, [daCode]);  // Trigger this effect whenever daCode changes
  

  const checkRegisterAbility = (event) => {
    // setImageSrc("/images/Contents/Icons/circle-check-solid.png")
    event.preventDefault(); 

    setLoading(true);

    // check codeProps if it is set
    console.log("codeProps:", codeProps);
    let request_url = "";
    if (codeProps) {
      request_url = `${process.env.REACT_APP_API_URL}/event/1/item/${codeProps}`;
    }else{
      request_url = `${process.env.REACT_APP_API_URL}/event/1/item`;
    }

    // request GET /api/event/1/item/
    axios.get(request_url, {
    }).then((response) => {
      const result = response.data

      setLoading(false);
      if(response.status !== 200){
        // server error
        alert("서버 오류가 발생하였습니다.\nServer error has occurred.");
      }
      if(result.item){
        console.log("found ga ticket");
        navigate("/register", { state: { event: result.event, item: result.item, code: codeProps } });

      }else{
        // alert("코드가 일치하지 않습니다.\nCode does not match.");
        setSwalProps({
          show: true,
          icon: "error",
          // title: 'Example',
          html: '예매상태가 아닙니다.<br>Register is not Avilable.',
          customClass: {
            popup: 'alert-popup',
            confirmButton: 'alert-popup-confirm-button',
          },
          backdrop: true,
          background: '#000',
          color: '#fff',
        }); 
        return;
      }
    });
  
  };

  const navigateRegisterInfo = (event) => {
    event.preventDefault(); //해당 문구를 추가해야 새로고침을 방지할 수 있다.
    navigate("/register/lookup");
  };

  return (

    <form style={{ marginBottom: "4em" }}>
      {/* {daCode} */}
      <button 
        id="btn-register"
        type="button" 
        className="btn-init-register"
        onClick={checkRegisterAbility} 
      >
        <img src={btn_register} alt="예매하기"></img>
      </button>
      
      <button 
        id="btn-register-check"
        type="button" 
        className="btn-init-register"
        onClick={navigateRegisterInfo} 
      >    
        <img src={btn_register_check} alt="예매 내역 조회"></img>
      </button>
      
      <FerrisWheelSpinnerOverlay
      　　loading={loading} 
       overlayColor="rgba(0,0,0, 0.6)"
       color="#fff"
        size={30} />
      <SweetAlert2 {...swalProps}
          onResolve={result => {
            setSwalProps  ({ show: false });
          }}
      />
    </form>
    
  );
}

