import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FerrisWheelSpinnerOverlay } from 'react-spinner-overlay'
import SweetAlert2 from 'react-sweetalert2';
import axios from "axios";

import btn_home from '../../media/ablesun/register/info/btn_home.svg';


export default function RegisterInfoForm() { 
  let [loading, setLoading] = useState(false);
  const [swalProps, setSwalProps] = useState({});
  const [codeProps, setCodeProps] = useState({});
  const [registerProps, setRegisterProps] = useState({});

  const location = useLocation();
  
  const eventState = { ...location.state?.event };
  const itemState = { ...location.state?.item };
  const registerState = { ...location.state?.registration };

  const [ticketCount, setTicketCount] = useState(registerState?.ticket_count);  
  const ticketPrice = itemState?.item_price;  

  // setTicketCount();
  
  console.log(eventState)
  console.log(itemState)

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to main page if eventState or event_no is not available
    if (!eventState || eventState.event_no === undefined) {
      navigate("/");
    }
  }, [eventState, navigate]); // Add navigate and eventState as dependencies


  const formatPrice = (price) => {
    return price.toLocaleString('en-US'); // Formats the number with commas
  };

  const formatPayStat = (payStat) => {
    // N : 미결제, Y : 결제완료, C : 결제취소, R : 환불요청, S : 입장완료
    // @if($register['pay_stat'] == 'Y')
    // <span style="color:lightseagreen">입금완료</span>
    // @elseif($register['pay_stat'] == 'R')
    //     <span style="color:red">환불</span>
    // @elseif($register['pay_stat'] == 'C')
    //     <span style="color:red">취소</span>
    // @elseif($register['pay_stat'] == 'N')
    //     <span style="color:red">미입금</span>
    // @elseif($register['pay_stat'] == 'S')
    //     <span style="color:blue">입장완료</span>
    // @else
    //     {{$register['pay_stat']}}
    // @endif
    switch (payStat) {
      case "N":
        return "미결제";
      case "Y":
        return "결제완료";
      case "C":
        return "결제취소";
      case "R":
        return "환불요청";
      case "S":
        return "입장완료";
      default:
        return payStat;
    }
  }
  return (
    <form >
        {/* date */}
        <div className="register-form-subject register-fields no-margin-bottom">
          <div className="register-form-title">
            <div className="title"> 
            티켓 정보
            </div>
            {/* <div className="label"> 
            Ticket Info
            </div> */}
          </div>
        <div className="register-form-value">
        <div className="title"> {eventState.event_name}  </div>
            {/* <div >{eventState.event_name}</div> */}
            <div className="label"> {itemState.item_name}</div>
          </div>
        </div>
      {/* registation number */}
      <div className="register-form-subject register-fields no-margin-bottom">
        <div className="register-form-title">
          <div className="title"> 예매번호 </div>
          {/* <div className="label"> Reg Number </div> */}
        </div>
        <div className="register-form-value">
          <input type="text" name="name" disabled value={registerState.reg_uid} />
        </div>
      </div>
      {/* registation number */}
      <div className="register-form-subject register-fields no-margin-bottom">
        <div className="register-form-title">
          <div className="title"> 예매상태 </div>
          {/* <div className="label"> Reg Status </div> */}
        </div>
        <div className="register-form-value">
          <input type="text" name="name" disabled value={formatPayStat(registerState.pay_stat)} />
        </div>
      </div>
      {/* name */}
      <div className="register-form-subject register-fields no-margin-bottom">
        <div className="register-form-title">
          <div className="title"> 입금자명 </div>
          {/* <div className="label"> Name </div> */}
        </div>
        <div className="register-form-value">
          <input type="text" name="name" disabled value={registerState.reg_bank_name} />
        </div>
      </div>
      {/* Ticket Count */}
      <div className="register-form-subject register-fields no-margin-bottom">
        <div className="register-form-title">
          <div className="title"> 예매수량 </div>
          {/* <div className="label"> Ticket Count </div> */}
        </div>
        <div className="register-form-value">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                <span style={{  fontSize: "2em" }}>{ticketCount}</span>

            <span style={{  textAlign: "right" ,  fontSize: "1.8em" }}>₩ {formatPrice(ticketCount * ticketPrice)}</span>
          </div>
        </div>
      </div>
      {/* email */}
      <div className="register-form-subject register-fields no-margin-bottom">
        <div className="register-form-title">
          <div className="title"> 이메일 </div>
          {/* <div className="label"> E-mail </div> */}
        </div>
        <div className="register-form-value">
          <input type="email" name="email" disabled value={registerState.reg_mail}/>
        </div>
      </div>

      {/* phone Number */}
      <div className="register-form-subject register-fields">
        <div className="register-form-title">
          <div className="title"> 연락처 </div>
          {/* <div className="label"> Cell Phone </div> */}
        </div>
        <div className="register-form-value">
          <input type="text" name="tel" disabled value={registerState.reg_call}/>
        </div>
      </div>

      {/* <input type="hidden" name='code' value={codeState.code} /> */}

      <button 
        id="btn-home"
        type="submit" 
        className="svg-btn btn-home"
        onClick={() => { navigate("/") }}
      >    
        <img src={btn_home} alt="홈으로"></img>
      </button>

      <FerrisWheelSpinnerOverlay
        loading={loading} 
        overlayColor="rgba(0,0,0, 0.6)"
        color="#fff"
        size={30} />
    </form>
  );
}

